// import '../../styles/Sections.css';
// import {  Button } from 'react-bootstrap';


export default function Hero(){

    return(
    
<div className="container-fluid">

  <div className="row justify-content-center align-items-center mx-2 hero">
    {/*  text content */}
    <div className="py-lg-0 col-md-6 col-xs-12 p-3">
      <h1 className="mb-3" style={{ fontSize:"2.5em" , fontWeight:'700'}}>
        <strong>Turning Ideas Into Impactful Online Experiences</strong>
      </h1>
      <p className="header-light mb-4 lead" style={{ fontSize:"1.125em" , fontWeight:'400'}}>
        Creating stunning websites that captivate your audience and drive results is my specialty.
        With my full stack development expertise, I offer end-to-end solutions for website designing, building, and hosting.
      </p>
      <nav className="links">
        <a  className='btn btn-primary' href="#projects">View Projects</a>
        {/* <Button variant="primary" className="scroll-to special" href="#projects">View Projects</Button> */}
        {/* <button  className="btn btn-primary scroll-to special" href="#projects">View Projects</button> */}
        <span className="mx-2">or</span>
        <a  className='btn btn-outline-primary' href="#aboutMe">Read About Me</a>
        {/* <Button variant="outline-secondary" className="internal special" href="#aboutMe">Read About Me</Button> */}
      </nav>
    </div>

    {/*  image */}
    <div className="py-lg-0 col-md-6 col-xs-12 p-2 text-center">
      <video src="/assets/hero.mp4" width="100%" alt="Hero" autoPlay loop muted></video>
    </div>

  </div>

</div>

 
        
        
  
    );
}