import React from "react"
import Hero from '../components/common/HeroSection';
import AboutMe from '../components/common/AboutSection';
import Process from '../components/common/ProcessSection';
import Projects from '../components/common/PortfolioSection';
import FeauterProjects from '../components/common/FeauterProjects';
import BrandCarousel from '../components/common/BrandCarousel';
import Cta from '../components/common/CTA';
import ContactMe from '../components/common/Contact';



export default function Home ()  {
  return (
    <>

    <section className=' my-5'><Hero></Hero></section>
    <section className=' my-5' style={{backgroundColor:'black'}} id="aboutMe"><AboutMe></AboutMe></section>
    <section className=' my-5 'id="services"><Process></Process></section>
    {/* <section className=' my-5 'id="projects"><Projects></Projects></section> */}
    <section className=' my-5 py-5 py-xl-6 'id="projects"><FeauterProjects></FeauterProjects></section>
    {/* <section className=' my-5'id="BrandCarousel"><BrandCarousel></BrandCarousel></section> */}
    <section className='' id="CTA"><Cta></Cta></section>
    <section className='' id="contact"><ContactMe></ContactMe></section>
    
    </>
  )
};
